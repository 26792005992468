<template>
  <vCard :col="12" header="Voertuig">
    <div class="vehicle-header">
          <span>
            <div class="avatar"
                 style="background: url('https://s3.vaartland.nl/foto/YL7GdbOd7QFTeD8729jDzrZMJGJY4AB9vUQF1dkN5tWBv.jpg')"></div>
            <div>
              <h2>{{ make }}</h2>
              <h4>{{ model }}</h4>
            </div>
          </span>
      <div>
        progress <span>{{ 50 }}%</span>
        <d-progress :value="50" :max="100" theme="default"/>
      </div>
    </div>
  </vCard>
</template>

<script>
export default {
  props: {
    make: {
      required: true
    },
    model: {
      required: true
    }
  }
}
</script>

<style lang="scss">
.vehicle-header {
  margin-bottom: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  > span {
    display: flex;
    align-items: center;

    > div {
      margin: 5px;
    }
  }

  > *:last-child {
    align-self: center;
  }

  .avatar {
    min-height: 120px;
    min-width: 120px;
    background-size: cover !important;
    background-position: center !important;
    border-radius: 50%;
    border: 3px solid lightgray;
    box-shadow: 0 0 10px lightgray;
  }
}

//.table th, .table td {
//  padding-left: 30px;
//  padding-bottom: 0;
//}
</style>
